var watchMediaException = false;
var eventSource = "";
var initialLoad = true;
var isSPDPage = false;

$(function() {
    $container = $('#secondaryItems, .secondaryTiles');
    // init
    if ($container.length) {
        $container.isotope({
            // options
            itemSelector: '.taxonomyTile',
            layoutMode: 'fitRows',
        });
    }
    $container = $('.filler');
    if ($container.length) {
        $container.isotope({
            // options 
            itemSelector: '.grid-item', 
            layoutMode: 'packery',
            columnWidth: 310,
            rowHeight: 310,
            packery: {
                gutter: 10

            }
        });
    }

    //Temporarily wrap feature images in a section due to retargeting of .featureWrappers to avoid colorBlock conflicts
    $('.titleTopNavBreadcrumbWrapper + .featureWrapper.emptyFeatureWrapper, .titleTopNavBreadcrumbWrapper + .featureWrapper.noBottomPadding, #pageAlertModal + .featureWrapper.emptyFeatureWrapper, ' +
        '.alertTextContainer + .featureWrapper.emptyFeatureWrapper, .featureWrapper.emptyFeatureWrapper.featureWrapperShort').wrap('<div class="fakeSection"></div>');

    $('.programTile')
        .each(function(index) {
            if ($(this).data("backgroundurl") && $(this).data("backgroundurl").length > 0) {
                $(this).css('background-image', 'url(' + $(this).data("backgroundurl") + ')');
            }
        });

    $('div.fakeSection .featureWrapper, section .featureWrapper')
        .each(function(index) {
            if ($(this).data("backgroundurl") && $(this).data("backgroundurl").length > 0) {
                $(this).css('background-image', 'url("' + $(this).data("backgroundurl") + '")');
                $(this).attr('style', $(this).data("style"));
            }
        });

    // Completely hides filtered out tiles & their parent wrappers
    function srTileFilter(filteredItems) {
        // Reset --- Hide all tile wrappers from ADA tools
        $('.taxonomyTileLinkWrapper').attr('tabindex', '-1');
        // Allow filtered tiles to be visible to ADA tools
        $.each(filteredItems, function(index, item) {
            $(item.element).parent('.taxonomyTileLinkWrapper').removeAttr('tabindex');
        });
    }

    $services = $('#topicServices, #featuredTopicServices');
    // init
    if ($services.length) {
        $services.isotope({
            // options
            itemSelector: '.taxonomyTile',
            layoutMode: 'fitRows'
        });
    }

    $programs = $('#topicPrograms');
    // init
    if ($programs.length) {
        $programs.isotope({
            // options
            itemSelector: '.taxonomyTile',
            layoutMode: 'fitRows'
        });
    }

    $topics = $('.TopicFilterComponent .topicList');
    // init
    if ($topics.length) {
        $topics.isotope({
            // options
            itemSelector: '.taxonomyTile',
            layoutMode: 'fitRows'
        });
        //$topics.isotope('on','layoutComplete', srTileFilter); 
    }

    if ($(".tileContactNavigation").length) {
        $(".tileContactNavigation")
            .isotope({
                itemSelector: '.taxonomyTile',
                layoutMode: 'fitRows'
            });
    }
    //Save Original Feature Tile Layout --- Only for webdevs that like looking at fluid layouts
    $desktopFeaturedTileLayout = $('#featuredTileContainer').clone(true);

    if (window.matchMedia) {
        var mq = window.matchMedia("(min-width: 991px)");
//        var desktop960 = window.matchMedia("(min-width: )")

        try {
            mq.addListener(RenderFeaturedContainer);
        } catch (e) {
            watchMediaException = true;
        } finally {
            RenderFeaturedContainer(mq);

            //if (isSPDPage) {
            $('#parkContactPopover, .closeParkContact, #parkHourPopover, .closeParkHours')
                .on('click',
                    function(e) {
                        if (mq.matches || watchMediaException) {
                            if (e.currentTarget.className == 'closeParkContact pull-right') {
                                $('#parkContactPopover').popover('hide');
                            } else if (e.currentTarget.className == 'closeParkHours pull-right') {
                                $('#parkHourPopover').popover('hide');
                            }
                        } else {
                            if (e.currentTarget.id == "parkHourPopover") {
                                $('#parkHoursExpanded').toggle("slide", { direction: "up" });
                            } else if (e.currentTarget.id == "parkContactPopover") {
                                $('#parkContactExpanded').toggle("slide", { direction: "up" });
                            }
                        }
                    });
            //}
        }
    } else {
        $('#featuredTilesCarousel').carousel();
        $("div.fakeSection .featureWrapper")
            .css('background-image', 'url(' + $("div.fakeSection .featureWrapper").data("backgroundurl") + ')');
        $("section .featureWrapper")
            .css('background-image', 'url(' + $("section .featureWrapper").data("backgroundurl") + ')');
        $('.featureTile')
            .each(function(index) {
                $(this).css('background-image', 'url(' + $(this).data("backgroundurl") + ')');
            });
        $primaryItems = $('#featuredTileContainer');
        if ($primaryItems.length) {
            $primaryItems.isotope({
                // options
                itemSelector: '.taxonomyTile',
                layoutMode: 'packery'
            });
        }
    }

    $('#topicFilterBtn')
        .click(function() {
            $('#topicFilterContainer, #additionalFiltersWrap').toggle("slide", { direction: "up" });
        });
    $('.contactTilePhysicalAddress, .contactTileMailingAddress')
        .on('show.bs.collapse',
            function() {
                $('a[href="#' + $(this).attr('id') + '"]').closest('.contactTileContentBlock').children('div').hide();
            });
    $('#footerOffice, #footerMailing')
        .on('show.bs.collapse',
            function() {
                $('#deptFootContactWrapper > div').hide();
            });
    $(".closeAddress")
        .click(function() {
            $(this).parent().removeClass("in");
            if ($(this).closest('.contactTile.taxonomyTile').length > 0) {
                $(this).parent().siblings(":not(.collapse)").children(':not(.fax, .tty, .contactPageLink)').show()
            } else {
                $(this).parent().siblings(":not(.collapse)").children().show()
            }
        }); 

    //Footer 2018
    //remove the class from top requests
    $('#seaFooter18Col3 [id^="top-request-wrapper"]').each(function() {
        this.className = '';
    });
    //append the href of report a problem to its ghost div
    if ($('#seaFooter18Col1 #col1Link0').length > 0) {
        var targetHref = $('#seaFooter18Col1 #col1Link0 a').attr('href');
        $('#seaFooter18Col1 #col1Link0').click(function() {
            //set the interior href to empty
            $('#seaFooter18Col1 #col1Link0 a').removeAttr('href').removeAttr('target');
            window.open(targetHref, '_blank');
        });
    }

    //in mobile mode, move Policies links above log
    $(window).resize(function () {
        if ($(window).width() < 575) {
            $('.footerContainer.subLinks h2').removeClass('hidden');
            if ($('.seafooter18Wrapper.department .departmentExcerpt').length > 0) {
                $('.seafooter18Wrapper.department .departmentExcerpt').insertAfter('.footerBottom18Wrapper .subLinks');
            }
            $('.footerBottom18Wrapper .copyRight').insertAfter('.footerBottom18Wrapper .subLinks');
            if ($('.footerBottom18Wrapper #footerBottom18 .departmentExcerpt').length > 0) {
                $('.footerBottom18Wrapper .copyRight').insertAfter('.footerBottom18Wrapper .departmentExcerpt');
            }
        } else {
            $('.footerBottom18Wrapper .subLinks').insertAfter('.footerBottom18Wrapper .copyRight');
            $('.footerContainer.subLinks h2').addClass('hidden');
        }
    });

    // Footer2015 Tiles 
    $topicFooter = $('.footerTiles');
    // init
    if ($topicFooter.length) {
        $topicFooter.isotope({
            // options
            itemSelector: '.topicTile',
            layoutMode: 'fitRows'
        });
    }
    $('.dropdown-toggle').dropdown();

    //Shorten tile descriptions if they're too long
    $('.tileExcerpt')
        .text(function() {
            clipLength = 120;
            var title = $(this).siblings(".tileTitle").text();
            if (title != undefined && title != "" && $.trim(title).length < 28) {
                clipLength = 140;
            }
            if ($(this).text().length > clipLength) {
                return $.trim($(this).text())
                    .substring(0, clipLength)
                    .split(" ")
                    .slice(0, -1)
                    .join(" ") +
                    " [...]";
            }

        });

    /*temporary*/
    $('.topicTile .tileLink a').addClass("topicLink");

    //Smooth Scrolling Jump Links
    $('#topicFilterContainer')
        .localScroll({
            duration: 800,
            offset: -90
        });

    $('.jumpLinks li a').each(function() {
        var anchor = $(this).attr('href').split('#')[1];

        $(this).localScroll({
            target: $('#' + anchor + ''),
            duration: 800,
            offset: -60
        });
    });

    //Back to Top
    if ($('#backToTop').length) {
        $(document)
            .scroll(function() {
                scrollInitHeight = $("#mainColContent").height() - 350;
                $('#backToTop')
                    .toggleClass('hasScrolled', $(document).scrollTop() >= scrollInitHeight, 300, "easeOutSine");
            });
    }

    //Photo Credit Tooltip & Icon
    $('.photoCredit>a')
        .each(function() {
            $(this).attr('title', $(this).text());
            $(this).html('<i class="fas fa-camera"></i>');
        });
    $(".photoCredit>a").tooltip({ placement: 'left' });


    //Photo Credit button
    $("#closePhotoCredit").click(function () {
        $("#openPhotoCredit").show();
        $("#closePhotoCredit").hide();
    });

    $("#openPhotoCredit").click(function () {
        $("#openPhotoCredit").hide();
        $("#closePhotoCredit").show();
    });



    $("#featuredTilesCarousel .playPause.pause")
        .click(function(e) {
            e.preventDefault();
            $("#featuredTilesCarousel").carousel("pause");
            $(this).hide();
            $("#featuredTilesCarousel .playPause.play").show();
        });
    $("#featuredTilesCarousel .playPause.play")
        .click(function(e) {
            e.preventDefault();
            $("#featuredTilesCarousel").carousel("cycle");
            $(this).hide();
            $("#featuredTilesCarousel .playPause.pause").show();
        });

    // Fix spacing on department-non-homepage-w/-no-director breadcrumbs. 
    // Because no CSS cousin selectors exist...
    if (!$('#deptTitle').find('span.director').length) {
        $('#deptBreadCrumbsWrapper').addClass('noDirector');
    }

    ///* fontawesome v5 */
    //$('.featuredPhotoCredit .cameraIcon').on('click', function () {
    //    $('.featuredPhotoCredit .featuredPhotoCreditText').slideToggle();
    //    $('.featuredPhotoCredit .cameraIcon').hide();
    //    $('#featuredTileContainer').children().fadeOut();
    //});

    ///* fontawesome v5 */
    //$('.featuredPhotoCredit .closePhotoIcon').on('click', function () {
    //    $('.featuredPhotoCredit .featuredPhotoCreditText').hide();
    //    $('.featuredPhotoCredit .cameraIcon').slideToggle();
    //    $('#featuredTileContainer').children().fadeIn('fast');
    //});

    /* accessibility bandaids */
    $('td[valign="top"]').css('vertical-align', 'top').removeAttr('valign');
    $('td[align="left"]').removeAttr('align');
    $('table[border="0"]').removeAttr('border');
    $('td[align="center"]').css('text-align', 'center').removeAttr('align');

    //Remove Map & Refine on empty result pages
    if ($('h2.emptyResults').length > 0) {
        $('h2.emptyResults').parent('.col-md-push-6').removeClass('col-md-push-6');
        $('#map, .mobileResultsCount, .resultsCount, .dropdownToggle, #searchResultViewToggle').hide();
        $('.mobileResultsCount, #searchResultViewToggle').removeClass('visible-xs');
        $('#refineNewSearch').css('border', '0px');
        $('#searchRefineBlade').css('padding-bottom', '0px');
    }

    //Show/Hide More Content
    // Limit to Park Detail Pages
    if (($('.parkDetailPage').length || $('.locationPage').length)) {
        $('.bodyText, .parkHoursAndContactContainer').filter(function () {
            return $.trim($(this).text()) === ''
        }).remove()

    }

    //for the Parallax component
    $(window).resize(function () {
        var parallaxHeight = Math.max($(window).height() * 0.6, 200) | 0;
        $('.parallaxImageContainer').height(parallaxHeight);
    }).trigger('resize');

    //for the quote component
    //set the quoteImageContainer to the same height so it can be vertically aligned middle
    $('.quoteImageContainer').parent().css({ "height": "100%" });

    $('.padTop.MainColumnInsertComponent .colorBlock').removeClass('colorBlock');

    //featured page. Add a middle line separating the main col 2 col.
    if ($('.featuredPage.colorBlock').length) {
        //find the component with the tallest height.
        var mainColTwoColHeights = $('.MainColumnTwoColumnComponent.colorBlock .col-md-6.col-xs-12').map(function () {
            return $(this).height();
        }).get();

        var maxHeight = Math.max.apply(null, mainColTwoColHeights);

        //remove the extra height padding fromn the colorBlock class
        $(".MainColumnTwoColumnComponent.colorBlock").addClass("paddingZero");
        //add a border between the left and right col contents
        $(".MainColumnTwoColumnComponent.colorBlock .twoColLeft").css({ "height": maxHeight + "px" }).addClass("featuredPagePipe");
    }

    //Topic filter container, selects Popular Services by default on page load.
    $("input.popularServicesInput").trigger('click');
    //Filter tiles remove special characters in the string
    $('#topicServices .taxonomyTileLinkWrapper > div, .topicList .taxonomyTileLinkWrapper > div').each(function() {
        var dataFullText = $(this).attr('data-fulltext');
        $(this).attr('data-fulltext', dataFullText.replace(/[^\w\s]/gi, ''));
    });

    // Put image gallery thumbnails in slick carousel
    $('.photoGalleryImages.photoGalleryDisplay-Carousel').each(function () {
        RenderSlickPhotoGallery(this, 0, true);
    });

    $('#textFilter.design20').insertBefore($('#topicFilterContainer'));

    //filter by text by enter key
    $('#filterTextInput').keypress(function (e) {
    	if (e.keyCode == 13) {
            filterCats();
            return false;
        }
    });

    // Fix for extra long excerpts in dropdowns
	$('.top-subnav').each(function() {
		var menu = $(this).height(),
			sub = 0;

		$(this).find('.top-subnav__excerpt').each(function() {
			var e = $(this).height();
			if (e > sub) sub = e;
		});
		
		if (sub >= menu) $(this).css('min-height', sub + 30);
    });

    // COS-024 Department Dropdown Bugfix for Screen Reader Hovering
    var deptDropdown__topNavItem_ariaHoverFix = function() {
        var listItems = document.querySelectorAll('.top-nav__item');
      
        listItems.forEach(function(item) {
            var toggle = item.querySelector('button[data-toggle="dropdown"]');
    
            if (null != toggle) {
                item.onmouseenter = function() {
                    // console.log('hover ON: ', toggle);
                    item.classList.add('open');
                    toggle.setAttribute('aria-expanded', 'true');
                    $(this).find('.top-subnav').attr('aria-hidden', 'false');
                }
                item.onmouseleave = function() {
                    // console.log('hover OFF: ', toggle);
                    item.classList.remove('open');
                    toggle.setAttribute('aria-expanded', 'false');
                    $(this).find('.top-subnav').attr('aria-hidden', 'true');
                }
            }
        });
    }
    deptDropdown__topNavItem_ariaHoverFix();

    var deptDropdown__topSubNavLink_ariaHoverFix = function() {
        var listItems = document.querySelectorAll('.top-subnav__link');
      
        if (null != listItems) {
            listItems.forEach(function(item) {     
                item.onmouseenter = function() {
                    $(this).parent().siblings('.top-subnav__excerpt').attr('aria-hidden', 'true');
                    $(this).siblings('.top-subnav__excerpt').attr('aria-hidden', 'false');
                }
                item.onmouseleave = function() {
                    $(this).parent().siblings('.top-subnav__excerpt').attr('aria-hidden', 'false');
                    $(this).siblings('.top-subnav__excerpt').attr('aria-hidden', 'true');
                }
            })
        }
    }
    deptDropdown__topSubNavLink_ariaHoverFix();

    var deptDropdown__topSubnav_ariaHoverFix = function() {
        var listItems = document.querySelectorAll('.top-nav__list .top-nav__item');
      
        if (null != listItems) {
            listItems.forEach(function(item) {
        
                item.onmouseenter = function() {
                    $(this).find('.top-subnav span.top-subnav__excerpt:first()').attr('aria-hidden', 'false');
                    //console.log('enter');
                }
                item.onmouseleave = function() {
                    $(this).find('.top-subnav span.top-subnav__excerpt:first()').attr('aria-hidden', 'true');
                    //console.log('leave');
                }
            })
        }
    }
    deptDropdown__topSubnav_ariaHoverFix();

    //add descriptive title to tableau iframe
    if ($('.tableauPlaceholder').length) {
        setTimeout(function () {
            $('.tableauViz').attr('title', $('.tableauPlaceholder').closest('.reportsSection').attr('aria-label') + ' Tableau dashboard');
        }, 1)
    }

    if (isAuthenticityBannerPresent()) { 

        //show the banner
        $('#header .officialBanner').removeClass('hidden').addClass('display');
        //adjust the header height
        $('#header').addClass('banner');

        //if the banner is wider than the header, sync it up
        if ($('#header .officialBanner').width() > $('#header').width()) {
            $('#header .officialBanner').css('width', $('#header').width() + 'px');
        }

        $('#header .officialBanner .link').click(toggleOfficialBannerExcerpt);

        //handle keyboard return for the banner excerpt
        $('#header .officialBanner .link').keypress(function (e) {
            if (e.keyCode == 13) {	
                toggleOfficialBannerExcerpt();
            }
        });

        //close the banner
        $('#header .officialBanner .button').click(closeOfficialBanner);

        //handle keyboard return for the close banner
        $('#header .officialBanner .button').keypress(function (e) {
            if (e.keyCode == 13) {	
                closeOfficialBanner();
            }
        });

        //wrap the excerpt when in mobile 400px or less
        if ($(window).width() <= 440) {
            $('#header .officialBanner .excerpt').addClass('wrap');
        }
    }



    //TEMP 5/9/2023 - until _helpers.cshtml goes to prod
    if ($('.TopicFilterComponent').length) {
        //remove the role and aria-label attributes from the filter checkbox element
        $('#topicFilterContainer #taxonomyTermGroup .checkbox, #additionalFiltersWrap .checkbox').each(function() {
            $(this).removeAttr('role').removeAttr('aria-label');
        });
        //remove the aria-label from the topicList ice cubes
        $('.topicList a .taxonomyTile').each(function() {
            $(this).removeAttr('aria-label');
        });
        //edit the aria-labelledby value and add the id to the .topicSubhead.keyword element
        $('#topicFilterContainer #filterTextInput').attr('aria-labelledby', 'filterByKeyword');
        $('#topicFilterContainer .topicSubhead.keyword').attr('id', 'filterByKeyword');
    }
});

//determines if the city authenticity banner is present on the page
function isAuthenticityBannerPresent() {
    var present = false;

    if (OfficialBanner_FF === true) { 
        //official banner expand/collapse toggle
        if ($('#header .officialBanner').length) {
            var sessionKey = sessionStorage.getItem('officialBannerViewed');

            //hide the official seattle banner if it was already viewed and closed
            if (sessionKey == '1') {
                $('#header .officialBanner').hide();
            }

            //toggles the expand and collapse of the official seattle banner
            if (sessionKey == null || sessionKey == '0' || sessionKey == '') {
                present = true;
            }
        }
    }

    return present;
}

/* LEGACY SDOT topic page filtering begin 
this is the version with a select drop down in the main col*/
function applyFilter(input, extraDimension) {

    //this function is for the additional taxonomy filter
    if (extraDimension && $('#additionalFiltersWrap').length > 0) {

        if (input.click) {
            //on click of the additional filter that's not the default selection
            $('#additionalFilterDefault').removeAttr('selected');
            $('.additionalFilter').remove();
            //remove any exisiting hidden additional filter checkboxes
            $('.additionalFilterDiv').remove();

            input = $('#additionalFilters option:selected');

            if (input.index() > 0) {

                //add hidden additional filter checkboxes
                //build out an input checkbox and label for the additional filter but keep it hidden
                var additionalFIlterCheckbox = '<div class="checkbox additionalFilterDiv hidden"><input id=' + input.attr('id') +
                    ' class="categoryInputs additionalFilterInput" type="checkbox" checked="checked" value="' + input.attr('value') +
                    '"></div>';
                //append the div and input field html
                $('#taxonomyTermGroup').append(additionalFIlterCheckbox);

                $('#appliedFiltersContainer').append($("<button/>",
                {
                    "class": "appliedFilterButton additionalFilter",
                    text: $(input).text().trim(),
                    value: input.attr('id'),
                    "aria-label": "Close " + $(input).text().trim() + " filter"
                }).append("<i class='fas fa-times'></i>")
                .click(function() {
                    //select the 0 index of the select box and click it to remove the current additional filter
                    $('#additionalFilters :first-child').prop('selected', true).trigger('change');
                }));

                //if there are no results after the filter, render a message
                displayAdditionalFilterNoResultsMessage();
            }
        }

        //else, default.
    } else if (input.checked) {
        $('#appliedFiltersContainer').append($("<button/>", { 
            "class": "appliedFilterButton", 
            text: $(input).parent().text().trim(), 
            value: input.id, 
            "aria-label": "Close " + $(input).parent().text().trim() + " filter" }).append("<i class='fas fa-times'></i>")
                .click(function() { $(input).click(); $(this).remove(); })); 
    } else {
        $("[value='" + input.id + "']").remove();
        //$(input).closest('.checkbox').attr('aria-checked', false);   
    }

    //hide show the 'clear all filters' button
    ToggleClearFiltersButton();

    var checked = $('#taxonomyTermGroup :checked');
    var filterCats = "";

    if (input.value == "*") {
        filterCats = "*";
        $('.categoryInputs').each(function (index, value) {
            value.checked = false;
        });
    }
    else {
        $('#allChk').attr("checked", false);
        checked.each(function (index, value) {

            if ($('.taxonomyTile.textSearch').length > 0) {
                filterCats += ".category" + value.value + ".textSearch, ";
            } else {
                var additionalFilterClass = '';
                //if the additional filters was selected create a css class with its id value to append to the ice cubes
                if ($("#additionalFilters option:selected").index() > 0) {
                    additionalFilterClass = ".category" + $("#additionalFilters option:selected").val() + "";
                }

                //append the additional filter class to the ice cubes for extra filtering.
                if ($(".categoryInputs:not('.additionalFilterInput'):checkbox:checked").length > 0) {

                    if (value.value != $("#additionalFilters option:selected").val()) {
                        filterCats += ".category" + value.value + additionalFilterClass + ", ";
                    }
                } 
                //default
                else {
                    filterCats += additionalFilterClass + ", ";
                }
            }

        });

        if (filterCats.length > 0) {
            filterCats = filterCats.substring(0, filterCats.length - 2);
        } else if ($('.taxonomyTile.textSearch').length > 0) {
            filterCats = ".textSearch";
        } else {
            $('#allChk').attr("checked", true);
        }
    }

    $('#topicServices, .topicList').isotope({ filter: filterCats });

    UpdateServicesCount();

    //if there are no results after the filter, render a message
    if ($('#additionalFiltersWrap').length > 0)
    {
        displayAdditionalFilterNoResultsMessage();
    }

    return true;
}

/* DESIGN20 SCL topic page filtering begin 
This has checkboxes as additional filters in the left nav*/
function filterTiles(input) {
    if (input.checked) {
        $('#appliedFiltersContainer').append($("<button/>", 
            { 
                "class": "appliedFilterButton", 
                text: $(input).parent().text().trim(), 
                value: input.id, 
                "aria-label": "Close " + $(input).parent().text().trim() + " filter" 
            }).append("<i class='fas fa-times'></i>").click(function () { $(input).click(); $(this).remove(); }));
    }
    else {
        $("[value='" + input.id + "']").remove();
        //$(input).closest('.checkbox').attr('aria-checked', false);  
    }

    //look for duplicate applied filter buttons when using the map filter - NFlores 012423
    var seen = {};
    $('#appliedFiltersContainer .appliedFilterButton').each(function () {

        var txt = $(this).val();
        //alert(txt);
        if (seen[txt])
            $(this).remove();
        else
            seen[txt] = true;
    });

    $('#appliedFiltersContainer .appliedFilterButton').click(function () {
        var btnVal = $(this).attr("value");
        if (btnVal === "categoryInput2782") {
            //remove the check mark in the 'Outside of Seattle' checkbox
            $('#outsideSeattle').prop("checked", false);
        }

    });

    var checked = $('#taxonomyTermGroup :checked, #additionalFiltersWrap :checked');
    var filterCats = "";


    var standardFilters = [];
    var additionalFilterClass = '';

    if (input.value == "*") {
        filterCats = "*";
        $('.categoryInputs').each(function (index, value) {
            value.checked = false;
        });
    }
    else {
        $('#allChk').attr("checked", false);
        checked.each(function (index, value) {

            if ($('.taxonomyTile.textSearch').length > 0) {
                filterCats += ".category" + value.value + ".textSearch, ";
            } else {
                additionalFilterClass = '';
                //get the additional filters.
                $('#additionalFiltersWrap input[type="checkbox"]').each(function() {
                    if ($(this).prop('checked')) {
                            additionalFilterClass += ".category" + $(this).attr('value') + "";
                    }
                });

                standardFilters = [];
                //get the standard filters.
                $('#taxonomyTermGroup .categoryInputs:not(.additionalFilterInput)').each(function() {
                    if ($(this).prop('checked')) {
                            standardFilters.push(".category" + $(this).attr('value'));
                    }
                });
            }
        });

        //build each standard filter with the set(s) of additional filters for isotope
        standardFilters.forEach(function(filter) {
            filterCats += filter + additionalFilterClass + ', ';
        });

        //if standard filters are empty, just filter additonal filters
        if (standardFilters.length == 0) {
            filterCats = additionalFilterClass + ', ';
        }

        if (filterCats.length > 0) {
            filterCats = filterCats.substring(0, filterCats.length - 2);
        } else if ($('.taxonomyTile.textSearch').length > 0) {
            filterCats = ".textSearch";
        } else {
            $('#allChk').attr("checked", true);
        }
    }

    $('#topicServices, .topicList').isotope({ filter: filterCats });
    UpdateServicesCount();

    //hide show the 'clear all filters' button
    ToggleClearFiltersButton();

    //if there are no results after the filter, render a message
    if ($('#additionalFiltersWrap').length > 0)
    {
        displayAdditionalFilterNoResultsMessage();
    }

    return true;
}

function filterCats() {
    $('#topicServices .taxonomyTile, .topicList .taxonomyTile').removeClass('textSearch');
    var input = $('#filterTextInput').val().toLowerCase().replace(/[^\w\s]/gi, '');

    dataLayer.push({ 'refineKeyword': input });
    var textSearch = '.textSearch';
    var output = "";
    $("#topicFilterContainer .btn.clearFilters").css("display", "block");
    if (input.length > 3) {
        $('*[data-fullText~="' + input + '"]').each(function (index, value) {
            $(value).addClass('textSearch');
        });
    }
    else {
        textSearch = "";
        $("#topicFilterContainer .btn.clearFilters").css("display", "none");
    }
    $('#taxonomyTermGroup :checked').each(function (index, value) {
        output += ".category" + value.value + textSearch + ", ";
    });
    if (output.length > 0) {
        textSearch = output.substring(0, output.length - 2);
    }

    $('#topicServices, .topicList').isotope({ filter: textSearch });
    UpdateServicesCount();

    displayAdditionalFilterNoResultsMessage();
}

function UpdateServicesCount() {
    if ($('#topicServices').length > 0) {
        $('#servicesCount').html("<span class=\x22topicCount\x22>" + $('#topicServices').data('isotope').filteredItems.length + "/" + totalServices + "</span> Services");
    } 
    else if ($('.TopicFilterComponent').length > 0) {
        var filteredCount = $('.topicList').data('isotope').filteredItems.length,
            totalCount = $('.topicList').data('isotope').items.length;
 
        $('.TopicFilterComponent .topicCount').text( filteredCount+ "/" + totalCount);
        $('#filteredAnnouncement').text('There are now ' + filteredCount + ' out of ' + totalCount + ' items displayed.');
    }
}

//hide show the 'clear all filters' button
function ToggleClearFiltersButton() {
    if ($('#appliedFiltersContainer .appliedFilterButton').is(':visible')) {
        $("#topicFilterContainer .btn.clearFilters").css("display", "block");
    }
    else {
        $("#topicFilterContainer .btn.clearFilters").css("display", "none");
    }
}

// clear topics checkboxes, tags, and textsearch 
function clearFilter() {

    $('button.appliedFilterButton').hide();
    $("#topicFilterContainer .btn.clearFilters").css("display", "none");
    $('input:checkbox').each(function () {
        this.checked = false;
    });
    $('#filterTextInput').val('');
    $('#topicServices, .topicList').isotope({ filter: "*" });
    $('#topicServices .taxonomyTile, .topicList .taxonomyTile').removeClass('textSearch');

    //set the additional filter select to the 0 index
    if ($('#additionalFiltersWrap').length > 0)
    {
        $('#additionalFilters :first-child').prop('selected', true);

        //if there are no results after the filter, render a message
        displayAdditionalFilterNoResultsMessage();
    }

    UpdateServicesCount();
}

// no additional filtered results message
function displayAdditionalFilterNoResultsMessage() {
    //if there are no results after the filter, render a no filtered results found message
    if ($('#topicServices, .topicList').data('isotope').filteredItems.length == 0) {

        var standardCheckedFilters = '';

        //in addition to the additional filter label, add the labels from the left nav checked boxes to render the 0 results message
        $('.categoryInputs:checkbox:checked').each(function() {
            var thisLabel = $(this).parent().text().trim();
            if (thisLabel != '') {
                standardCheckedFilters += thisLabel + ', ';
            }
        });

        var additionalFilterSelectedValue = $("#additionalFilters option:not(:first-child):selected").text().length > 0 ? ' ' + $("#additionalFilters option:selected").text() : '';
        var delimiter = additionalFilterSelectedValue.length > 0 ? ', ' : '';
        var textSearchInputValue = $('#filterTextInput').val().length > 0 ? delimiter + $('#filterTextInput').val() : '';

        standardCheckedFilters = standardCheckedFilters + additionalFilterSelectedValue + textSearchInputValue;

        $('.additionalFilterNoResult').html('The filter(s) for "' + standardCheckedFilters + '" returned 0 results. Please try again.').addClass('display');
    } else {
        $('.additionalFilterNoResult').html('').removeClass('hidden');
    }
}
/* topic page filtering end */

// media query change
function RenderFeaturedContainer(mq) {
    if (mq.matches || watchMediaException) {
        // window width is at least 991px
        //$('#searchIcon').attr('data-toggle', 'modal');
        if ($("div.fakeSection .featureWrapper").data("backgroundurl") != undefined) {
            $("div.fakeSection .featureWrapper").css('background-image', 'url(' + $("div.fakeSection .featureWrapper").data("backgroundurl") + ')');
        }
        if ($("section .featureWrapper").data("backgroundurl") != undefined) {
            $("section .featureWrapper").css('background-image', 'url(' + $("section .featureWrapper").data("backgroundurl") + ')');
        }

        /* FEATURE CONTAINER TILES //////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////*/

        //Make it clear this isn't mobile
        $('.featureWrapper').removeClass('mobile');

        //Enable Bootstrap Container Properties
        $(".featureWrapper>.bladeMode").switchClass('bladeMode', 'container', 0);

        //Target the Featured Tile Container
        $primaryItems = $('#featuredTileContainer');

        //Restore desktop content
        if (initialLoad) {
            initialLoad = false;
        }
        else {
            $primaryItems.html($desktopFeaturedTileLayout);
            $desktopFeaturedTileLayout = $('#featuredTileContainer').clone(true);
        }
        $('.featureTile').each(function (index) {
            $(this).css('background-image', 'url(' + $(this).data("backgroundurl") + ')');
        });

        //if there is a featued item carousel
        if ($('.featuredItemBackground').length > 0) {
            RenderFeatureItemCarouselCaption();

            //if there is onlly one featured item in the carousel add a special css class
            if ($('.featuredItemBackground').length == 1) {
                $('#featuredTilesCarousel').addClass('noCarousel');
            }
        }

        //if there is more than one feature tile in the carousel, keep the overlay gradient on for better carousel controls visibility
        if ($('#featuredTileContainer.extendedTileContainer .extendedFeatureTile.featureTile').length > 1) {
            $('#featuredTileContainer.extendedTileContainer .extendedFeatureTile.featureTile [id^="extPrimaryContent_"]').each(function() {
                if ($(this).find('.overlayBox').length == 0) {
                    $(this).append('<div class="overlayBox"></div>')
                }
            });
        }

        //Initate Isotope
        if ($primaryItems.length) {
            $primaryItems.isotope({
                // options
                itemSelector: '.taxonomyTile',
                layoutMode: 'packery'
            });
            $primaryItems.addClass("hasIsotope");
        }

        //Initiate the Carousel
        //don't auto start the carousel for accessibility issues
        $('#featuredTilesCarousel').carousel("pause");

        /* END FEATURE CONTAINER TILES ////////////////////////////////////////////////////*/

        //only on topic pages
        if ($('.topicPage').length) {
            //move related depts to sidebar
            $('#leftNav.relatedDepartments').parent('nav').insertAfter('.programJump');
            $('#leftNav .relatedListContainer').parent('#leftNav').insertAfter('.programJump');

            //position the additional filter component between Filter Services & Programs/Related Departments
            $('#additionalFiltersWrap').insertAfter($('#taxonomyTermGroup'));
        }
        // Fade Out Feature Container When Not In Focus  
        if (window.addEventListener) {
            window.addEventListener('scroll', function () {
                var scrollMax = 100;
                //Check if user has deprecated screen size
                if ($('section #featuredTileContainer').width() <= 1280) { scrollMax = 450; }
                if ($('section #featuredTileContainer').width() < 960) {
                }
                else if (window.scrollY > scrollMax) {
                    $('section #featuredTileContainer').children().fadeOut();
                }
                else {
                    $('section #featuredTileContainer').children().fadeIn('fast');
                }
            }, false);
        }
        //Park popover                
        $('#parkContactExpanded, #parkHoursExpanded').addClass("hide");
        $('#parkContactExpanded, #parkHoursExpanded').css("display", "");

        //launch popup only for SPD pages for now, no parks related pages
        if (isSPDPage) {
            $("#parkContactPopover").popover({html: true, placement: 'top', content: function() { return $("#parkContactExpanded").clone(true).removeClass('hide') }});
        }

        $("#parkHourPopover").popover({ html: true, placement: 'top', content: function () { return $("#parkHoursExpanded").clone(true).removeClass('hide') } });

        /* search collection form blade */
        if ($('.searchFormCollectionWrapper + #searchRefineBlade').length != 0) {
            $('.searchFormCollectionWrapper, .featureWrapperSlides, #refineNewSearch, #searchRefineBlade').attr('style', '');
        }

        if ($('.searchFormCollectionWrapper.mobileBlade').length != 0) {
            $('.searchFormCollectionWrapper.mobileBlade').remove();
            $('.featureWrapperSlides').slick();
            $('.featureWrapperSlides .slick-prev, .featureWrapperSlides .slick-next').click(function () {
                //Set select option to current slide
                $('select.findWhatTitle:visible').val($('.slick-active').attr('data-collectionType'));

                //Update Form Action
                UpdateFormAction();
            });
        }
        //Location Pages w/ Tilesets - Reset Page Title Location
        if ($('.locationPage').length > 0 && $('#featuredTileContainer').length > 0) {
            $('h1.pageTitle').insertAfter('#featuredTileContainer');
        }

        //Colorblocks. If the featured main image was size B by default, change it to an A size when the screen is stretched to a desktop resolution
        $(".secondaryTiles .colorBlockBTile").each(function (index) {
            $(this).css('background-image', 'url(' + $(this).attr("data-mobilebackground") + ')');
        });

        $('.seafooter18Wrapper .ContactComponent .contactAddress .phoneNumber').contents().wrap();
        $('.seafooter18Wrapper .ContactComponent .contactAddress .phoneAltNumber').contents().wrap();
    }
    else {
        // window width is less than 991px
        if ($('div.fakeSection .featureWrapper.emptyFeatureWrapper').length && $(".container").width() == 750) {
            $("div.fakeSection .featureWrapper").css('background-image', 'url(' + $("div.fakeSection .featureWrapper").data("backgroundurl") + ')');
        }
        if ($('section .featureWrapper.emptyFeatureWrapper').length && $(".container").width() == 750) {
            $("section .featureWrapper").css('background-image', 'url(' + $("section .featureWrapper").data("backgroundurl") + ')');
        }

        if (initialLoad) {
            initialLoad = false;
            $desktopFeaturedTileLayout = $('#featuredTileContainer').clone(true);
        }

        /* FEATURE CONTAINER TILES //////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////*/

        //Make it clear this is mobile
        $('.featureWrapper').addClass('mobile');

        //Switch A-Tiles to mobile friendly backgrounds
        $('.featureTile').each(function (index) {
            $(this).css('background-image', 'url(' + $(this).data("mobilebackground") + ')');
        });

        //Colorblocks. If the featured main image was size B by default, change it to an A size when the screen is pinched down to tablet resolution
        $(".secondaryTiles .colorBlockBTile").each(function (index) {
            $(this).css('background-image', 'url(' + $(this).attr("data-background") + ')');
        });

        // A Tile Seperation
        ATileDivorces();

        //Disable Bootstrap Container Properties
        $(".featureWrapper>.container").switchClass('container', 'bladeMode', 0);

        if ($('#featuredTileContainer').length) {
            //Since some tiles use #FeaturedTileContainer as a CSS selector---Don't move them outside of this container
            //Create 2 sub containers for SLickable & Non-slickable Items
            $('#featuredTileContainer').prepend('<div id="slickWilly"><div class="container" /></div><div id="nonSlickWilly"><div class="container" /></div>');

            //Move tiles into associated containers
            //themed tile set department with only service tiles
            if ($('#featuredTileContainer.noTile').length > 0) {
                $('#featuredTileContainer.noTile #nonSlickWilly').prepend('<div class="serviceTile noTile"></div>');
                $('#featuredTileContainer.noTile .taxonomyTile').appendTo('#featuredTileContainer.noTile #nonSlickWilly .serviceTile.noTile');
            }
            else {
                $('#featuredTileContainer .slickTile').appendTo('#slickWilly .container');
                $('#featuredTileContainer .taxonomyTile:not(.slickTile)').appendTo('#nonSlickWilly .container');
            }

            //Destroy Isotope
            if ($('#featuredTileContainer').hasClass("hasIsotope")) {
                $('#featuredTileContainer').isotope('destroy').removeClass("hasIsotope");
            }

            //Sort Slickable Tiles
            SortTiles($('#slickWilly .container'));

            //Sort Non-slickable Tiles
            SortTiles($('#nonSlickWilly .container'));


            var slideCount = 5;
            if ($("#slickWilly .container").width() > 680 && $("#slickWilly .container").width() <= 768) {
                slideCount = 4;
            } else if ($("#slickWilly .container").width() > 480 && $("#slickWilly .container").width() <= 680) {
                slideCount = 3;
            }
            else if ($("#slickWilly .container").width() < 280) {
                slideCount = 1;
            }
            else if ($("#slickWilly .container").width() <= 480) {
                slideCount = 2;
            }
            //Initiate Slick
            $('#slickWilly .container').slick({
                lazyLoad: 'ondemand',
                dots: false,
                infinite: true,
                arrows: false,
                speed: 300,
                swipeToSlide: true,
                variableWidth: true,
                slidesToShow: slideCount,
                slidesToScroll: slideCount
            });

            //Initiate Isotope
            $('#nonSlickWilly .container').addClass("hasIsotope");
            $('#nonSlickWilly .container').isotope({
                // options
                itemSelector: '.taxonomyTile',
                layoutMode: 'packery'
            });
        }

        //Begin - featuretile carousel
        //if there is only one featured image in the carousel, make it 100% width
        if ($('.featuredItemBackground.featureTile').length == 1) {
            $('#featuredTileContainer div:nth-child(3), #featuredTileContainer > #featuredTileContainer, .featuredItemBackground .caption').css({ "display": "block" });
        } else {
            $('.featuredItemBackground div:nth-child(3), #featuredTileContainer > #featuredTileContainer, .featuredItemBackground .caption').css({ "display": "none" });
        }

        if ($('.featuredItemBackground').length > 0) {
            RenderFeatureItemCarouselCaption();

            //if there is onlly one featured item remove the slickWilly div
            if ($('.featuredItemBackground').length == 1) {
                $('#slickWilly').css({"display": "none"});
            }
        }

        //if there is only one feature tile in the carousel, add a 'noCarousel' CSS class
        if ($('#featuredTileContainer.extendedTileContainer .extendedFeatureTile.featureTile').length == 1) {
            $('#featuredTileContainer.extendedTileContainer .extendedFeatureTile.featureTile').addClass('noCarousel');
        }
        //End - featuretile carousel

        /* END FEATURE CONTAINER TILES ////////////////////////////////////////////////////*/

        if ($('#additionalFiltersWrap').length > 0) {
            //add a new class to the smoothJump 'Programs' lable to move it down the page a bit more to accomodate for the extra select
            $('.smoothJump.programJumpMobile').addClass('additionalFilterOffSet');
        }

        //$('#searchIcon').removeAttr('data-toggle');
        //$('#searchIcon').attr('data-toggle', 'modal');

        /*DEPRECATED AFTER 7/26 DEPLOYMENT*/
        /*tablet resets left navigation/column */
        if ($("#leftCol").children().length == 1 && $('#leftCol').attr('class') == 'col-md-3 col-sm-3 col-sm-pull-9') {
            $("#leftCol").parent().siblings().removeClass("col-sm-push-3");
            $("#leftCol").parent().hide();
        }
        /*END DEPRECATED AFTER 7/26 DEPLOYMENT*/

        //only on topic pages
        if ($('.topicPage').length) {
            //move related depts to main column
            $('#leftNav.relatedDepartments').parent('nav').insertAfter('#topicProgramContainer');
            $('#leftNav .relatedListContainer').parent('#leftNav').insertAfter('#topicProgramContainer');
        }
        //Park collapse         
        $("#parkContactPopover, #parkHourPopover").popover('destroy');
        $('#parkContactExpanded, #parkHoursExpanded').removeClass("hide");
        $('#parkContactExpanded, #parkHoursExpanded').css("display", "none");

        /* search collection form blade */
        if ($('.searchFormCollectionWrapper + #searchRefineBlade').length > 0 && $(".container").width() < 750 && $('.paginationContainer').length != 0) {
            $('.searchFormCollectionWrapper, .searchFormWrapper').addClass('pseudoHidden');
            $('.featureWrapperSlides').css('position', 'relative');
        }

        $("#refineNewSearch").on("click", function () {
            if ($('#refineNewSearch > span:last-child').text() == 'New Search') {
                $('.searchFormCollectionWrapper, .searchFormWrapper').removeClass('pseudoHidden');
                $('.featureWrapperSlides').css('position', 'absolute');
                $('#leftMainRight, .mobileResultsCount, .dropdownToggle, #searchResultViewToggle, .resultsCount').hide();
                $('.mobileResultsCount, #searchResultViewToggle').removeClass('visible-xs');
                $('#refineNewSearch > span:last-child').text('Last Results');
                $('#refineNewSearch').css('border', '0px');
                $('#searchRefineBlade').css('padding-bottom', '0px');
                $('.findByInput input[type=text]').val('').attr('value', '');
                if ($('h2.emptyResults').length > 0) {
                    $('#searchRefineBlade').hide();
                }
            } else {
                $('.searchFormCollectionWrapper, .featureWrapperSlides, #refineNewSearch, #searchRefineBlade').attr('style', '');
                $('#leftMainRight, .mobileResultsCount, .dropdownToggle, #searchResultViewToggle, .resultsCount').show();
                $('.mobileResultsCount, #searchResultViewToggle').addClass('visible-xs');
                $('#refineNewSearch > span:last-child').text('New Search');
                $('.searchFormCollectionWrapper, .searchFormWrapper').addClass('pseudoHidden');
                $('.featureWrapperSlides').css('position', 'relative');
            }

        });

        //Parks - Bind Geolocation to Button
        if ($('#currentLocation').length > 0) {
            $('#currentLocation').click(function () {
                UseGeolocation();
            });
        }

        //Location Pages w/ Tilesets - Move Page Title above Tileset
        if ($('.locationPage').length > 0 && $('#featuredTileContainer').length > 0) {
            $('h1.pageTitle').insertBefore('.featureWrapper');
            $('h1.pageTitle').wrap('<div class="mobilePageTitle"><div class="container"><div class="row"></div></div></div>');
        }

        // Put images in slick carousel
        $('.photoGalleryDisplay-Grid').each(function () {
            RenderSlickPhotoGallery(this, 0, true);
        });

    }// end else

    //Close Alert Banner
    $("[id^='closeBannerBtn']").click(function() {
        $(this).parents("[id^='seagovBanner']").eq(1).slideUp().remove();
    })

    //Scroll for Contact drop down
    $('.jumpToOptions').change(
        function () {
            var aid = $('.jumpToOptions option:selected').val();
            if (aid != "Select One") {
                $("html,body").animate({ scrollTop: $("a[name='" + aid + "']").offset().top - 50 }, 500);
            }
        }
    );
}


function ChangePreview(theRow) {
    $("#resultTable .active").removeClass("active");
    $(theRow).addClass("active");
    if ($(theRow).data("thumbnail") != "") {
        $("#resultPreview img").attr("src", $(theRow).data("thumbnail"));
        $("#resultPreview .previewImageContainer").removeClass("hidden");
        $("#resultPreview .details").addClass("col-md-6");
        $("#resultPreview .details").removeClass("col-md-12");
    }
    else {
        $("#resultPreview .previewImageContainer").addClass("hidden");
        $("#resultPreview .details").addClass("col-md-12");
        $("#resultPreview .details").removeClass("col-md-6");
    }
    $("#resultPreview h2").text($(theRow).data("title"));
    $("#resultPreview .amenities").html($(theRow).data("features"));
    $("#resultPreview .excerpt").text($(theRow).data("excerpt"));
    $("#resultPreview .address").text($(theRow).data("address"));
    $("#resultPreview a").attr("href", $(theRow).data("link"));
    $("#resultPreview a").attr("title", $(theRow).data("title"));
    /* TODO add lat/lng and map centering */
    try {
        RenderParkLocations();
    }
    catch (parkRenderError) { }
}

//Loop 11 scripts
//var loop11_key = "ef28aa6454afdadbe1962267ebf212f588d1d766";
//document.write(unescape("%3cscript src='//cdn.loop11.com/my/loop11.js' type='text/javascript'%3e%3c/script%3e"));

function SortTiles(tiles) { //Sort Tiles From A to C
    //Could Redo Using a Data-Attribute Integer for more granular sort weight in MVC
    //Primitive Sort by Descending Tile Size. Sub-sorting in tile types is defined by original order. 
    $(tiles).find('.taxonomyTile.width2').appendTo(tiles); //Add A Tiles
    $(tiles).find('.taxonomyTile.height2:not(.width2)').appendTo(tiles); //Add B Tiles
    if ($(tiles).find('.slickTile').length > 0) {
        CTileTinder($(tiles).find('.taxonomyTile:not(.width2, .height2)'), tiles); //Add C Tiles
    } else {
        $(tiles).find('.taxonomyTile:not(.width2, .height2)').appendTo(tiles);
    }
}


function CTileTinder(tilesToWrap, allTiles) {   //Pair the C tiles together.
    for (var i = 0; i < tilesToWrap.length; i += 2) {
        tilesToWrap.slice(i, i + 2).appendTo(allTiles).wrapAll('<div class="nestedTileWrapper height2"></div>');
    }
}


function ATileDivorces() {  //A tiles need to be seperated & removed from the carousel

    //Give them the tile classes
    $('#featureTileWrapper').find('.featureTile').addClass('taxonomyTile width2 height2 slickTile');

    //Remove the carousel controls
    $('#featureTileWrapper .carousel-control').remove();

    //Clear all the carosuel classes & ids
    $('#featureTileWrapper, #featuredTilesCarousel, .carousel-inner').removeClass();
    $('#featureTileWrapper, #featuredTilesCarousel').attr('id', '');
}

function RenderFeatureItemCarouselCaption() {
    //Begin - featuretile carousel
    //default the maxHeight value
    var maxHeight = -1;
    $("#featuredTilesCarousel .caption, #featuredTileContainer .caption")
        .each(function () {
            //make all the background divs active so that we can read each height
            //we will take the tallest height to set the div
            $(this).closest('.featuredItemBackground').addClass('active');

            //get the tallest element
            if ($(this).height() > maxHeight) {
                maxHeight = $(this).height();
            }

            //remove all active classes except for the first instance;
            $('.caption').not(':first').closest('.featuredItemBackground').removeClass('active');
        });

    //add the maxHeight of the caption div to div.carousel-inner
    var carouselInnerHeight = $(".carousel-inner, .featuredItemBackground.featureTile").height();

    //set the height of the placeholder div above the caption
    $('.featuredItemBackground .placeholder').css({ "height": carouselInnerHeight + "px" });

    $(".featureItemCarousel .carousel-inner, .FeaturedItemCarousel .carousel-inner").css({ "height": (carouselInnerHeight + maxHeight) + 50 + "px" });
    //position featuredTilesCarousel caption below the height of the photo
    $(".featureItemCarousel .caption").css({ "height": maxHeight + "px", "top": $("#featuredTilesCarousel .featuredItemBackground").height() });

    //End - featuretile carousel
}

// Convert element to a slick carousel
function RenderSlickPhotoGallery(galleryElem, slideStart, centerSlides) {

    $(galleryElem).slick({
        infinite: true,
        speed: 300,
        initialSlide: slideStart,
        centerMode: centerSlides,
        variableWidth: true,
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false
                } 
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: false
                } 
            }
        ]
    });
}

//toggles the official seattle banner
var toggleOfficialBannerExcerpt = function() {  
    if ($('#header .officialBanner .content').hasClass('expanded')) {
        $('#header .officialBanner .content').removeClass('expanded').addClass('collapsed');
        $('#header .officialBanner .excerpt .link svg').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    } else {
        $('#header .officialBanner .content').removeClass('collapsed').addClass('expanded');
        $('#header .officialBanner .excerpt .link svg').removeClass('fa-chevron-down').addClass('fa-chevron-up');
    }

    //re-position the skip to nav if active ScrollSpy present
    if (document.querySelectorAll('.ScrollSpyContainerComponent').length > 0 && !document.querySelector('.ScrollSpyContainerComponent').classList.contains('inactive')) {
        attachAnchor();
    }
}

//closes the official seattle banner and sets session storage
var closeOfficialBanner = function() {
    $('#header .officialBanner').remove();

    //adjust the header height
    $('#header').removeClass('banner');
    //set the browse4 session storage instead of cookie
    sessionStorage.setItem('officialBannerViewed', 1);

    //update the height for the google translation discalimer modal
    var googleTranslationCookie = getCookie('googtrans');
    determineAdditionalHeight(googleTranslationCookie);

    //re-position the skip to nav if active ScrollSpy present
    if (document.querySelectorAll('.ScrollSpyContainerComponent').length > 0 && !document.querySelector('.ScrollSpyContainerComponent').classList.contains('inactive')) {
        attachAnchor();
    }
}